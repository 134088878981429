.App {
  text-align: center;
  background-color: #282c34ec;
}
.app-content {
  max-width: 1000px;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 10px;
}

.app-image {
  width: 100%;
  max-width: 100px;
}